import { Navigate, redirect, RouteObject } from "react-router-dom";
import Base from "./Base/Base";
import ErrorPage from "./errorPage";

import AppsumoSignUp from "./AppSumoSignup/AppSumoSignUp";
import Login from "./Login/Login";
import NewAuthBase from "./NewAuthBase/NewAuthBase";
import Signup from "./Signup/Signup";

import SubscriptionPlanManager from "./SubscriptionPlanManager/SubscriptionPlanManager";
// import ContentPlan from "./ContentPlan/ContentPlan";
import ShowV2Articles from "./Articles/ShowV2Articles";
import BackLinks from "./Backlinks/BackLinks";
import ConnectWebsite from "./ConnectWebsite/ConnectWebsite";
import AddAutomation from "./KeywordsResearchV2/AddAutomation";
import KeywordResearch from "./KeywordsResearchV2/KeywordResearch";
import CreateTitles from "./NewArticlePage/CreateTitles";
import NewSignUp from "./Signup/NewSignUp";
import { SurveyProvider } from "./Login/SurveyContext";
import NewIntegration from "./Integrations/NewIntegration";
import Glossary from "./Glossary/Glossary";
import GlossaryTopic from "./Glossary/GlossaryTopic";
import GlossaryHome from "./Glossary/GlossaryHome";
import GlossaryEditor from "./Glossary/GlossaryEditor";
import RedditPostFinder from "./RedditPostFinder/RedditPostFinder";
import RedditPostFinderView from "./RedditPostFinder/RedditPostFinderView";
import AppSumoErrorPage from "./appSumoErrorPage";

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
	ARTICLE_EDIT_PAGE_API,
	ARTICLES_PAGE_API,
	CONNECT_WEBSITE_PAGE_API, CONTACT_US_PAGE_API,
	CONTENT_AUTOMATION_PAGE_API,
	CREATE_ARTICLE_PAGE_API,
	GLOSSARY_TOPIC_API,
	GOOGLE_SUCCESS_PAGE_API,
	INTERNAL_LINKS_API,
	KEYWORD_PROJECT_KEYWORD_TITLES_PAGE_API,
	// WEBFLOW_SUCCESS_PAGE_API,
	// INDEXATION_PAGE_API,
	KEYWORD_RESEARCH_PAGE_API,
	// CONTENT_PLAN_PAGE_API,
	KEYWORDS_PAGE_API,
	LOGGED_IN_BASE_PAGE_API,
	LOGOUT_PAGE_API, MAX_WEBSITES_PAGE_API,
	PLANS_PAGE_API, PROFILE_PAGE_API,
	SETTINGS_PAGE_API,
	SIGNUP_PLAN_SELECTION_API, WP_SUCCESS_PAGE_API,
	BLOG_PROJECT_URLS_API,
	GLOSSARY_KEYWORD_HASH_API,
} from "../utils/api";
import { getAccessToken, getRefreshToken, removeAccessToken, removeRefreshToken, renewAccessToken } from "../utils/jwt";
import AccountEmailVerification from "./AccountEmailVerification/AccountEmailVerification";
import ArticleEditor from "./ArticleEditor/ArticleEditor";
import ForgotPassword from "./AuthBase/ForgotPassword";
import CheckoutSuccess from "./CheckoutSuccess/CheckoutSuccess";
import CompetitorResearch from "./CompetitorResearch/CompetitorResearch";
import CompetitorResearchKeywords from "./CompetitorResearchKeywords/CompetitorResearchKeywords";
import MaxWebsites from "./ConnectWebsite/MaxWebsites";
import ContactUs from "./ContactUs/ContactUs";
import GoogleSuccess from "./GoogleSuccess/GoogleSuccess";
import Profile from "./Profile/Profile";
import ResetPassword from "./ResetPassword/ResetPassword";
import Settings from "./Settings/Settings";
import SignupPlanSelection from "./SignupPlanSelection/SignupPlanSelection";
import WordpressSuccess from "./WordpressSuccess/WordpressSuccess";
// import WebflowSuccess from "./WebflowSuccess/WebflowSuccess";
// import Indexation from "./Indexation/Indexation";
import Calendly from "./Calendly/Calendly";
import CreateArticle from "./CreateArticle/CreateArticle";
import GoogleLoginAndSignup from "./GoogleLoginAndSignup/GoogleLoginAndSignup";
import ShowTitlesForKeyword from "./KeywordsResearchV2/ShowTitlesForKeyword";
import ProgrammaticSeo from "./ProgrammaticSeo/ProgrammaticSeo";
import TitleProject from "./ProgrammaticSeo/TitleProject";
import NotFound from "./NotFound/NotFound";
import Tutorials from "./Tutorials/Tutorials";
import InternalLinks from "./InternalLinks/InternalLinks";
import BlogFinder from "./BlogFinder/BlogFinder";
import BlogUrlTable from "./BlogFinder/BlogUrlTable"
import GuestPostFinder from "./GuestPostFinder/GuestPostFinder"
import GuestPostFinderView from "./GuestPostFinder/GuestPostFinderView"

export class RouterError extends Error {
	constructor(message: string) {
		super(`Routing Failed: ${message}`);
		this.name = "RouterError";
	}
}
const isProduction = process.env.REACT_APP_DRF_DOMAIN === "https://api.abun.com";
/**
 * Authenticates and fetches data from provided url path. Auto-renews access token in case it was expired.
 * Redirects to login page in case access token is missing, incorrect or expired.
 *
 * You need to call this on every page on this website other than login, signup and similar pages.
 *
 * @param path - server api url to fetch data from.
 * @returns Page data if successful. Otherwise returns redirect to login page.
 */
export async function getLoggedInPageData(path: string) {
	let accessToken = getAccessToken();

	if (accessToken) {
		let axiosConfig: AxiosRequestConfig = {
			method: 'get',
			url: process.env.REACT_APP_DRF_DOMAIN + path,
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}

		try {
			// axios sends all non-2xx response into catch() as an error
			let response = await axios(axiosConfig);
			if (response.data === undefined) {
				throw new RouterError("Received undefined data from the server");
			}
			return response.data;

		} catch (err) {
			const axiosError = err as AxiosError

			// encountered non-200x status codes
			if (axiosError.response?.status === 401) {
				// ----------------------- ACCESS/REFRESH TOKEN EXPIRED -----------------------
				// token expired. get new access token using refresh token
				let refreshToken: string | null = getRefreshToken();
				if (refreshToken) {
					let renewalSuccessful: boolean = await renewAccessToken(refreshToken);
					if (renewalSuccessful) {
						// renewal was successful. update access token in header value
						accessToken = getAccessToken();
						axiosConfig.headers = {
							'Authorization': 'Bearer ' + accessToken
						}
						// if it doesn't work this time, redirect to login page to prevent infinite loop
						try {
							let response = await axios(axiosConfig);
							return response.data;
						} catch (err) {
							return redirect(pageURL['login'])
						}
					} else {
						// Probably due to refresh token expiry. Redirect to login page.
						return redirect(pageURL['login'])
					}
				} else {
					// no refresh token found
					return redirect(pageURL['login']);
				}
			} else if (axiosError.response?.status === 402) {
				// ----------------------- USER HAS NOT SELECTED ANY PLAN -----------------------
				// Send them to signup plan selection page
				return redirect(pageURL['signupPlanSelection'])
			} else if (axiosError.response?.status === 302) {
				// ----------------------- OTHER REDIRECTS -----------------------
				let redirectLocation: string = (axiosError.response?.data as any)['redirect_to'];
				if (redirectLocation) {
					switch (redirectLocation) {
						case "dashboard":
							return redirect(pageURL['createArticle']);
						case "max_websites":
							return redirect(pageURL['maxWebsites']);
						default:
							throw new RouterError(`Unhandled redirect location ${redirectLocation}`);
					}
				} else {
					throw new RouterError("Recieved redirect status but no 'redirect_to' value");
				}

			} else {
				console.error(axiosError.response?.status);
				throw new RouterError(
					`encountered non-success status code ${axiosError.response?.status}
					 while trying to fetch logged in page data`
				)
			}
		}
	} else {
		// no jwt tokens stored in bowser (signed out)
		return redirect(pageURL['login']);
	}
}

/**
 * Similar to getLoggedInPageData() but for pages where user is logged out (no authentication is needed). In case
 * user is logged in (token present in browser, or other reasons) they will be redirected to 'dashboard' page.
 *
 * If 'path' value is not provided, only checks the access token and returns empty object.
 *
 * @param path - server api url to fetch data from.
 * @returns Page data if successful. Otherwise returns redirect to login page.
 */
async function getLoggedOutPageData(path?: string, queryParams?: Record<string, string>) {
	let accessToken = getAccessToken();

	if (accessToken) {
		// Preserve query parameters when redirecting
		const searchParams = queryParams ? '?' + new URLSearchParams(queryParams).toString() : '';
		return redirect(pageURL['createArticle'] + searchParams);
	}

	if (path) {
		let axiosConfig: AxiosRequestConfig = {
			method: 'get',
			url: process.env.REACT_APP_DRF_DOMAIN + path,
			responseType: 'json',
		}

		let response = await axios(axiosConfig);
		return response.data;
	} else {
		return {}
	}
}

/**
 * Logs out the user by calling logout api and also deleting the local access & refresh tokens.
 * Incase of any server or client side error, redirects to dashboard page
 */
async function logout(): Promise<Response> {
	const refreshToken: string | null = getRefreshToken();
	// no/empty refresh token found.
	if (!refreshToken) {
		// clean up just in case the keys were remaining
		removeAccessToken();
		removeRefreshToken();
		return redirect(pageURL['login']);
	}

	// call `logout api` to blacklist current tokens
	try {
		axios({
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + LOGOUT_PAGE_API,
			data: {
				'refresh': refreshToken
			},
			responseType: 'json'
		});
	} catch (err) {
		// Logout failed due to client or server error. Return to dashboard page
		console.error(err);
		return redirect(pageURL['createArticle'])
	}

	// delete access tokens in browser storage & redirect to login page
	removeAccessToken();
	removeRefreshToken();
	return redirect(pageURL['login']);
}


// =========================================================================================
// --------------------------- ROUTING CONFIGURATION STARTS HERE ---------------------------
// =========================================================================================

// use this only for manually redirecting users through typescript code
// for all other scenarios, use react-router `Link` component
// when adding a new URL, update both `pageURL` and `routes`
export const pageURL = {
	'signup': "/auth/signup",
	'login': "/auth/login",
	'forgotPassword': "/auth/forgot-password",
	'signupPlanSelection': "/signup-plan-selection/",
	'googleLogin': "/auth/accounts/google/login",
	'googleSignup': "/auth/accounts/google/signup",
	'createTitle': "/auth/get-title",
	'newSignUp': "/auth/new-signup",
	'appSumoSignUp': "/auth/appsumo/signup",

	'resetPassword': "/reset-password/:encryptedEmail/:resetUID",
	'accountEmailVerification': "/verify-account-email/:token",

	'contact': "/contact-us",
	'manageSubscription': "/manage-subscription",
	'profile': "/profile",
	'websites': "/websites",
	'settings': "/settings",
	'new-integration': "/integrations",
	'integrationWizard': "/integration/:service",
	'connectWebsite': "/connect-website",
	'maxWebsites': "/max-websites",
	// 'contentPlan': "/content-plan",
	'articleEdit': "/articles/edit/:articleUID",
	'competitorResearch': "/keywords/competitor-research",
	'competitorResearchKeywords': "/keywords/competitor-research/:compDomain",
	// 'indexation': "/indexation",

	'wordpressSuccess': "/integration/wp/success/:encryptedDomain",
	'webflowSuccess': "/integration/webflow/success",
	"googleSuccess": "/integration/google/success",

	'checkoutSuccess': "/checkout/success",

	'integration': "/settings?tab=integration",

	'showArticles': "/show-articles",
	'keywordResearch': "/keyword-research",
	'showKeywords': "/show-keywords",
	'contentCalendar': "/content-calendar",
	'addAutomation': "/add-automation",
	'tutorials': "/tutorials",

	'showTitlesForKeyword': "/keyword-project/:keywordProjectId/titles/:keywordHash",

	'backlinks': "/backlinks",
	'guestPostFinder': "/guest-post-finder",
	'guestPostFinderView': "/guest-post-finder/view/:queryID",
	'redditPostFinder': "/reddit-post-finder",
	'redditPostFinderView': "/reddit-post-finder/view/:queryID",
	'getDemo': "/get-demo",
	'glossaryHome': "/glossary-home",
	'glossary': "/glossary-generator",
	'glossaryTopic': "/glossary-generator/:projectId",
	'glossaryEditor': "/glossary-content/:keywordHash",
	"createArticle": "/create-article",
	'programmaticSeo': "/programmatic-seo",
	'titleProject': "/title-project",
	'listOfTitles': "/list-of-titles/:keywordProjectId/titles/:keywordHash",
	'internalLinks': "/internal-links",
	'notfound': "/404",
	'blogFinder': "/blog-finder",
	'blogUrlTable': "/blog-urls-table/:blogProjectId/:blogProjectName"
}

const routes: RouteObject[] = [
	// ---------- LOGGED OUT / AUTH PAGES ----------
	{
		path: "/auth",
		element: <NewAuthBase />,
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Navigate to={pageURL['login']} replace={true} />
			},
			{
				path: "signup",
				element: <Signup />,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: "login",
				element: <Login />,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: 'forgot-password',
				element: <ForgotPassword />,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: pageURL['googleSignup'],
				element: <GoogleLoginAndSignup signup={true} />,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: pageURL['googleLogin'],
				element: <GoogleLoginAndSignup signup={false} />,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: pageURL['createTitle'],
				element: <CreateTitles />,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
		]
	},

	{
		path: "/auth/appsumo/signup",
		element: <AppsumoSignUp />,
		loader: async ({ request }) => {
			const url = new URL(request.url);
			const queryParams = Object.fromEntries(url.searchParams);
			return await getLoggedOutPageData(undefined, queryParams);
		}
	},

	// ---------- LOGGED IN PAGES ----------
	{
		path: "/",
		element: <Base />,
		errorElement: <AppSumoErrorPage />,
		id: "base",
		loader: async () => {
			return await getLoggedInPageData(LOGGED_IN_BASE_PAGE_API);
		},
		children: [
			{
				index: true,
				element: <Navigate to={pageURL['createArticle']} replace={true} />
			},
			{
				path: pageURL['profile'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<Profile />
					</SurveyProvider>
				),
				loader: async () => {
					const profilePageData = await getLoggedInPageData(PROFILE_PAGE_API);

					return {
						pageData: profilePageData,
					};
				}
			},
			// {
			// 	path: pageURL['contentPlan'],
			// 	element: <ContentPlan/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(CONTENT_PLAN_PAGE_API);
			// 	}
			// },
			{
				path: pageURL['contact'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<ContactUs />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(CONTACT_US_PAGE_API);
				}
			},
			{
				path: pageURL['showArticles'],
				element: (
					<SurveyProvider disableSurvey={false}>
						<ShowV2Articles />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(ARTICLES_PAGE_API);
				}
			},
			{
				path: pageURL['articleEdit'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<ArticleEditor />
					</SurveyProvider>
				),
				loader: async ({ params }) => {
					return await getLoggedInPageData(ARTICLE_EDIT_PAGE_API + "?article_uid=" + params.articleUID);
				}
			},
			{
				path: pageURL['keywordResearch'],
				element: (
					<SurveyProvider disableSurvey={false}>
						<KeywordResearch />
					</SurveyProvider>
				),
				loader: async () => {
					const keywordResearchPageData = await getLoggedInPageData(KEYWORD_RESEARCH_PAGE_API);

					return {
						pageData: keywordResearchPageData,
					};
				}
			},
			{
				path: pageURL['competitorResearch'],
				element: (
					<SurveyProvider disableSurvey={false}>
						<CompetitorResearch />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(KEYWORDS_PAGE_API);
				},
			},
			{
				path: pageURL['addAutomation'],
				element: (
					<SurveyProvider disableSurvey={false}>
						<AddAutomation />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(CONTENT_AUTOMATION_PAGE_API);
				},
			},
			{
				path: pageURL['competitorResearchKeywords'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<CompetitorResearchKeywords />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(KEYWORDS_PAGE_API);
				},
			},
			{
				path: pageURL['manageSubscription'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<SubscriptionPlanManager />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(PLANS_PAGE_API);
				}
			},
			// {
			// 	path: pageURL['indexation'],
			// 	element: <Indexation/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(INDEXATION_PAGE_API);
			// 	}
			// },
			{
				path: pageURL['settings'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<Settings />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(SETTINGS_PAGE_API);
				}
			},
			{
				path: pageURL['new-integration'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<NewIntegration />
					</SurveyProvider>
				),
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['tutorials'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<Tutorials />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['showTitlesForKeyword'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<ShowTitlesForKeyword />
					</SurveyProvider>
				),
				loader: async ({ params }) => {
					return await getLoggedInPageData(KEYWORD_PROJECT_KEYWORD_TITLES_PAGE_API + `?keyword_project_id=${params.keywordProjectId}&keyword_hash=${params.keywordHash}`);
				}
			},
			{
				path: pageURL['backlinks'],
				element: <BackLinks />,
				loader: async () => {
					return getLoggedInPageData("/api/frontend/get-backlink/");
				}
			},
			{
				path: pageURL['internalLinks'],
				element: (
					<SurveyProvider disableSurvey={true}>
						<InternalLinks />
					</SurveyProvider>
				),
				loader: async () => {
					return await getLoggedInPageData(INTERNAL_LINKS_API);
				}
			},
			{
				path: pageURL['guestPostFinder'],
				element: <GuestPostFinder />,
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['guestPostFinderView'],
				element: <GuestPostFinderView />,
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['redditPostFinder'],
				element: <RedditPostFinder />,
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['redditPostFinderView'],
				element: <RedditPostFinderView />,
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['getDemo'],
				element: <Calendly />,
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['glossaryHome'],
				element: <GlossaryHome />,
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['glossary'],
				element: <Glossary />,
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['glossaryTopic'],
				element: <GlossaryTopic />,
				loader: async ({ params }) => {
					return await getLoggedInPageData(GLOSSARY_TOPIC_API + `?project_id=${params.projectId}`);
				}
			},
			{
				path: pageURL['glossaryEditor'],
				element: <GlossaryEditor />,
				loader: async ({ params }) => {
					return await getLoggedInPageData(GLOSSARY_KEYWORD_HASH_API + `?keyword_hash=${params.keywordHash}`);
				}
			},

			{
				path: pageURL['createArticle'],
				element: (
					<SurveyProvider disableSurvey={false}>
						<CreateArticle />
					</SurveyProvider>
				),
				loader: async () => {
					const createArticlePageData = await getLoggedInPageData(CREATE_ARTICLE_PAGE_API);

					return {
						pageData: createArticlePageData,
					};
				}
			},
			{
				path: pageURL['notfound'],
				element: <NotFound />
			},
			{
				path: pageURL['programmaticSeo'],
				element: (
					isProduction ? <Navigate to="/404" /> :
						<SurveyProvider disableSurvey={false}>
							<ProgrammaticSeo />
						</SurveyProvider>
				),
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['titleProject'],
				element: (
					isProduction ? <Navigate to="/404" /> :
						<SurveyProvider disableSurvey={false}>
							<TitleProject />
						</SurveyProvider>
				),
			},
			{
				path: pageURL['listOfTitles'],
				element: isProduction ? <Navigate to="/404" /> : <ShowTitlesForKeyword />,
				loader: async ({ params }) => {
					return await getLoggedInPageData(KEYWORD_PROJECT_KEYWORD_TITLES_PAGE_API + `?keyword_project_id=${params.keywordProjectId}&keyword_hash=${params.keywordHash}`);
				}
			},
			{
				path: pageURL['blogFinder'],
				element: (
					isProduction ? <Navigate to="/404" /> :
						// <SurveyProvider disableSurvey={false}>
						<BlogFinder />
					// </SurveyProvider>
				),
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['blogUrlTable'],
				element: (
					isProduction ? <Navigate to="/404" /> :
						// <SurveyProvider disableSurvey={false}>
						<BlogUrlTable />
					// </SurveyProvider>
				),
				loader: async ({ params }) => {
					const pageData = await getLoggedInPageData(
						BLOG_PROJECT_URLS_API + `?blogProjectId=${params.blogProjectId}&blogProjectName=${params.blogProjectName}`
					);
					return { pageData, params };
				},
			},
		]
	},
	// ---------- CONNECT WEBSITE WIZARD ----------
	{
		path: pageURL['connectWebsite'],
		element: <ConnectWebsite />,
		errorElement: <ErrorPage />,
		loader: async () => {
			return await getLoggedInPageData(CONNECT_WEBSITE_PAGE_API);
		}
	},
	{
		path: pageURL['maxWebsites'],
		element: <MaxWebsites />,
		errorElement: <ErrorPage />,
		loader: async () => {
			return await getLoggedInPageData(MAX_WEBSITES_PAGE_API);
		}
	},
	// ---------- WP INTEGRATION SUCCESS ----------
	{
		path: pageURL['wordpressSuccess'],
		element: <WordpressSuccess />,
		errorElement: <ErrorPage />,
		loader: async ({ request, params }) => {
			const encryptedDomain: string | undefined = params.encryptedDomain;
			const siteURL = new URL(request.url).searchParams.get('site_url');
			const userLogin = new URL(request.url).searchParams.get('user_login');
			const password = new URL(request.url).searchParams.get('password');
			return getLoggedInPageData(WP_SUCCESS_PAGE_API + `?ed=${encryptedDomain}&surl=${siteURL}&ul=${userLogin}&k=${password}`);
		}
	},
	// ---------- WEBFLOW INTEGRATION SUCCESS ----------
	// {
	// 	path: pageURL['webflowSuccess'],
	// 	element: <WebflowSuccess/>,
	// 	errorElement: <ErrorPage/>,
	// 	loader: async ({request}) => {
	// 		const url = new URL(request.url);
	// 		const state = url.searchParams.get('state');
	// 		const code = url.searchParams.get('code');
	// 		return getLoggedInPageData(WEBFLOW_SUCCESS_PAGE_API + `?state=${state}&code=${code}`);
	// 	}
	// },
	// ---------- GOOGLE INTEGRATION SUCCESS ----------
	{
		path: pageURL['googleSuccess'],
		element: <GoogleSuccess />,
		errorElement: <ErrorPage />,
		loader: async ({ request }) => {
			const state = new URL(request.url).searchParams.get('state');
			const code = new URL(request.url).searchParams.get('code');
			const scope = new URL(request.url).searchParams.get('scope');
			const integrationType = localStorage.getItem('integration-type');
			return getLoggedInPageData(GOOGLE_SUCCESS_PAGE_API + `?state=${state}&code=${code}&scope=${scope}&integration-type=${integrationType}`);
		}
	},

	// ---------- CHECKOUT SUCCESS ----------
	{
		path: pageURL['checkoutSuccess'],
		element: <CheckoutSuccess />,
		errorElement: <ErrorPage />,
	},
	// ---------- MISC. PAGES ----------
	{
		path: pageURL['signupPlanSelection'],
		element: <SignupPlanSelection />,
		loader: async () => {
			return getLoggedInPageData(SIGNUP_PLAN_SELECTION_API);
		}
	},
	{
		path: pageURL['resetPassword'],
		element: <ResetPassword />
	},
	{
		path: pageURL['accountEmailVerification'],
		element: <AccountEmailVerification />
	},
	{
		path: "/logout",
		loader: async () => {
			return await logout();
		}
	},
	{
		path: pageURL['newSignUp'],
		element: <NewSignUp />,
		loader: async () => {
			return getLoggedOutPageData();
		}
	},
]

export default routes;

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { useNavigate } from "react-router-dom";
import LocalIcon from "../../components/Icon/Icon";
import { storeAccessToken, storeRefreshToken } from "../../utils/jwt";
import { pageURL } from "../routes";
import './NewSignUp.scss';
export default function NewSignUp() {

    // ----------------------- STATES -----------------------
    const [signupUnderway, setSignupUnderway] = useState(false);
    const [googleSignupUnderWay, setGoogleSignupUnderWay] = useState(false);
    const [Error, setError] = useState('');
    const [modalText, setModalText] = useState("");
    const [requestModalActive, setRequestModalActive] = useState(false);
    const failAlertRef = useRef<any>(null);

    // ----------------------- REFS -----------------------
    // TODO: need to replace this sometime later
    const usernameElementRef = useRef<HTMLInputElement>(null);
    const emailElementRef = useRef<HTMLInputElement>(null);
    const passwordElementRef = useRef<HTMLInputElement>(null);
    const submitButtonRef = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Signup | Abun"
    }, []);


    /* -------------- validation functions -------------- */
    function usernameIsValid(value: string): boolean {
        return /^[a-zA-Z0-9\s]+$/.test(value) && (value.length <= 35);
    }

    function emailIsValid(value: string): boolean {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
    }

    function passwordIsValid(value: string): boolean {
        return value.length >= 6
    }

    /* ------------------------------------------------- */

    /**
     * Resets all previous highlights and messages when called.
     */
    function clearAllInputErrorHighlights() {
        if (usernameElementRef.current && emailElementRef.current && passwordElementRef.current) {
            usernameElementRef.current.classList.remove('is-danger');
            emailElementRef.current.classList.remove('is-danger');
            passwordElementRef.current.classList.remove('is-danger');
            if (usernameElementRef.current.parentElement) {
                usernameElementRef.current.parentElement.dataset.errMsg = "";
            }
            if (emailElementRef.current.parentElement) {
                emailElementRef.current.parentElement.dataset.errMsg = "";
            }
            if (passwordElementRef.current.parentElement) {
                passwordElementRef.current.parentElement.dataset.errMsg = "";
            }
        } else {
            console.error("clearAllInputErrorHighlights() - input element reference is missing");
        }
    }

    /**
     * Higlights input and shows error message below them. Resets all previous highlights and messages when called.
     * @param input: key representing the input field that needs to be marked
     * @param errorMsg: message to show below the input field
     */
    function markBadInput(input: "username" | "email" | "password", errorMsg: string) {
        if (usernameElementRef.current && emailElementRef.current && passwordElementRef.current) {
            // reset all previous error messages and border highlights
            clearAllInputErrorHighlights();

            switch (input) {
                case "username":
                    usernameElementRef.current.classList.add('is-danger');
                    if (usernameElementRef.current.parentElement) {
                        usernameElementRef.current.parentElement.dataset.errMsg = errorMsg;
                    }
                    break;
                case "email":
                    emailElementRef.current.classList.add('is-danger');
                    if (emailElementRef.current.parentElement) {
                        emailElementRef.current.parentElement.dataset.errMsg = errorMsg;
                    }
                    break;
                case "password":
                    passwordElementRef.current.classList.add('is-danger');
                    if (passwordElementRef.current.parentElement) {
                        passwordElementRef.current.parentElement.dataset.errMsg = errorMsg;
                    }
                    break;
                default:
                    console.error("bad 'input' argument value");
                    break;
            }
        } else {
            console.error("markBadInput() - input element reference is missing");
        }
    }

    function setServerErrorMessage(err_id = 'server_error') {
        if (usernameElementRef.current) {
            if (usernameElementRef.current.parentElement) {
                if (err_id === 'DOMAIN_BLOCKED') {

                    usernameElementRef.current.parentElement.dataset.errMsg = "We do not accept temporary/disposable emails.";
                }
                else if (err_id === 'ACCOUNT_ALREADY_EXISTS') {

                    usernameElementRef.current.parentElement.dataset.errMsg = "Account already exists. Please login to continue.";
                }
            }

        } else if (submitButtonRef.current) {
            submitButtonRef.current.dataset.errMsg = "We are experiencing some issues. Please try again later.";

        }
    }

    function clearServerErrorMessage() {
        if (submitButtonRef.current) {
            submitButtonRef.current.dataset.errMsg = "";
        }
    }

    async function submitSignupData() {
        clearServerErrorMessage();
        setSignupUnderway(true);

        const username: string = usernameElementRef.current?.value || "";
        const email: string = emailElementRef.current?.value || "";
        const password: string = passwordElementRef.current?.value || "";
        const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

        /* check the input values */
        if (!usernameIsValid(username)) {
            markBadInput(
                "username",
                "Full Name should only contain alphanumeric characters & spaces"
            );
            setSignupUnderway(false);
            return;
        }

        if (!emailIsValid(email)) {
            markBadInput("email", "please enter a valid email address");
            setSignupUnderway(false);
            return;
        }

        if (!passwordIsValid(password)) {
            markBadInput("password", "password should have minimum of 6 characters");
            setSignupUnderway(false);
            return;
        }

        // let endpoint = "https://pro.ip-api.com/json/?fields=status,country&key=GN0GifpuBrQppdE";
        let endpoint = "https://api.ipdata.co/?fields=country_name&api-key=4346a4c48105f4c24084f18b6badc1ed363ab8bdd96420a2b070c10f";
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                let response = JSON.parse(this.responseText);

                /* submit data to server */
                clearAllInputErrorHighlights();
                axios({
                    method: "post",
                    url: process.env.REACT_APP_DRF_DOMAIN + "/api/frontend/signup/",
                    responseType: 'json',
                    data: {
                        username: username,
                        email: email,
                        password: password,
                        country: response.country_name,
                        timezone: timezone
                    }
                }).then(response => {
                    if (response.status === 200) {
                        // Account created successfully. Redirect to next page.
                        let result = response.data;
                        let accessToken: string = result['access_token'];
                        let refreshToken: string = result['refresh_token'];

                        storeAccessToken(accessToken);
                        storeRefreshToken(refreshToken);
                        return navigate(pageURL['signupPlanSelection']);
                    } else {
                        setSignupUnderway(false);
                        setServerErrorMessage();
                    }
                }).catch(err => {
                    console.error(err);
                    setServerErrorMessage(err.response.data.err_id);
                    setSignupUnderway(false);
                });
            }
        };
        xhr.open('GET', endpoint, true);
        xhr.send();
    }

    const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }

    return (

        <div className="ca-signup-container">
            <div className="card signup-card mt-5">
                <div className="card-content">
                    <h1 className="heading-text epilogue">SignUp For Free To View Article</h1>
                    <div className="content has-text-centered">
                        {/* ------------------ USERNAME ------------------ */}
                        <div className="field">
                            <div className="control show-error-message-above" data-err-msg="">
                                <input ref={usernameElementRef}
                                    className="input is-medium"
                                    type="text"
                                    id="username"
                                    name="username"
                                    placeholder="Full Name" />
                            </div>
                        </div>
                        {/* ------------------ EMAIL ID ------------------ */}
                        <div className="field" style={{ marginTop: "2rem" }}>
                            <div className="control show-error-message-below" data-err-msg="">
                                <input ref={emailElementRef}
                                    className="input is-medium"
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email ID" />
                            </div>
                        </div>
                        {/* ------------------ PASSWORD ------------------ */}
                        <div className="field" style={{ marginTop: "2rem" }}>
                            <div className="control show-error-message-below" data-err-msg="">
                                <input ref={passwordElementRef}
                                    className="input is-medium"
                                    type={type}
                                    id="password"
                                    name="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password" />
                                <span className="eye-icon" onClick={handleToggle}>
                                    <Icon icon={icon} size={25} style={{ color: 'grey-dark' }} />
                                </span>
                            </div>
                        </div>
                        <p className="is-size-7 mt-5" style={{ color: "#5D5E98" }}>
                            By signing up you
                            agree to our <a href="https://abun.com/terms-conditions/" rel={"noreferrer"} target={"_blank"}>Terms & Conditions</a>&nbsp;
                            and <a href="https://abun.com/privacy-policy/" rel={"noreferrer"} target={"_blank"}>Privacy Policy</a>.
                        </p>

                        {signupUnderway ? 
                            <button className="button signup-In-btn-border is-large is-responsive mt-5 show-error-message-below"
                            style={{ borderRadius: "8px", color: "#000" }}
                            data-err-msg="">
                                <span>Signing Up...&nbsp;&nbsp;<LocalIcon iconName={"spinner"} additionalClasses={["icon-white"]} /></span>
                            </button> 
                        :
                        <button ref={submitButtonRef}
                            className="button signup-btn-border is-large is-responsive mt-5 show-error-message-below"
                            style={{ borderRadius: "8px", color: "#000" }}
                            disabled={signupUnderway || googleSignupUnderWay}
                            onClick={submitSignupData}
                            data-err-msg="">
                                <span>SIGNUP →</span> 
                        </button>
                        }
                        <div className="divider-wrapper mt-4 mb-4">
                            <span className="divider">OR</span>
                        </div>
                        <div className='connect-account'>
                            <button
                                disabled={signupUnderway || googleSignupUnderWay}
                                onClick={() => {
                                    setGoogleSignupUnderWay(true);
                                    clearServerErrorMessage();

                                    axios({
                                        method: "get",
                                        url: process.env.REACT_APP_DRF_DOMAIN + "/api/fontend/google-signup-login-auth/?signup=true",
                                        responseType: 'json',
                                    }).then((response) => {
                                        if (response.status === 200) {
                                            const auth_url = response.data.authorization_endpoint;
                                            window.location.href = auth_url;
                                        } else {
                                            setGoogleSignupUnderWay(false);
                                            setServerErrorMessage();
                                        }
                                    })

                                }}
                                className='button signup-btn-border'>
                                <LocalIcon iconName='google' />
                                {
                                    googleSignupUnderWay ?
                                        <span className='ml-3'>
                                            Sign up with Google...&nbsp;&nbsp;<LocalIcon iconName={"spinner"} additionalClasses={["icon-white"]} />
                                        </span> :
                                        <span className='ml-3'>
                                            Sign up with Google →
                                        </span>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

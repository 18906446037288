import { useEffect } from "react";
//import "./Calendly.scss"

export default function Calendly() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);

        return () => {

            document.body.removeChild(script);
        };
    }, []);
    return (<>
        <div className="modal-content" style={{ overflow: 'hidden', width: "100%", zoom: "100%" }}>
            <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/junaidansari/15min"
                style={{ height: '98vh', overflow: 'hidden' }}
            ></div>
        </div>
    </>)
}
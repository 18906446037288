import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState, useRef } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import { getGuestPostFinderQueries, makeApiRequest } from "../../utils/api";
import LinkButton from "../../components/LinkButton/LinkButton";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunButton from "../../components/AbunButton/AbunButton";

interface Query {
    id: string,
    query: string,
    limit: number,
    is_processing: boolean,
    created_at: Date
}

interface UserData {
	verified: boolean
    username: string
    website: string
    email: string
    tz: string
    send_notif_email: boolean
}

export default function GuestPostFinder() {
    // ----------------------------- LOADER -----------------------------
    const pageData: UserData = useLoaderData() as UserData;

    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 25, 50, 100];

    // -------------------------- STATES --------------------------
    const [queries, setQueries] = useState<Array<Query>>([]);
    const [topic, setTopic] = useState("")
    const [limit, setLimit] = useState(10);
    const navigate = useNavigate();
    const [gpfQueriesGenerated, setGpfQueriesGenerated] = useState(0);
    const [currentPlanName, setCurrentPlanName] = useState('');
    const [maxGpfAllowed, setMaxGpfAllowed] = useState(0);
    const [showWarning, setShowWarning] = useState(false);


    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch
    } = useQuery({
        queryKey: ['getGuestPostFinderQueries'],
        queryFn: getGuestPostFinderQueries,
        refetchOnWindowFocus: false
    });


    // -------------------------- REFS --------------------------
    const errorAlertRef = useRef<any>(null);
    const successAlertRef = useRef<any>(null);

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setQueries(data['data']['queries']);
            setGpfQueriesGenerated(data.data.gpf_queries_generated);
            setCurrentPlanName(data.data.current_plan_name);
            setMaxGpfAllowed(data.data.max_gpf_allowed);
        }
    }, [data]);

    useEffect(() => {
        document.title = "Guest Post Finder | Abun"
    }, []);

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<Query>();
    const columnDefs: ColumnDef<any, any>[] = [

        columnHelper.accessor((row: Query) => row.query, {
            id: 'topic',
            header: "Topic",
            cell: (props) => {
                const handleClick = () => {
                    navigate(`/guest-post-finder/view/${props.row.original.id}/`);
                };

                return (
                    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {props.row.original.query}
                    </span>
                );
            },
            enableGlobalFilter: true
        }),
        columnHelper.accessor((row: Query) => row.limit, {
            id: 'limit',
            header: "Limit",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
        columnHelper.accessor((row: Query) => row.created_at, {
            id: 'date',
            header: "Created On",
            cell: cellProps => {
                let selectedDate: any = cellProps.row.original.created_at;

                // defining date showing context
                const getRelativeTime = (dateString: string) => {
                    const createdDateObj = new Date(dateString);
                    const now = new Date();
                    const timeDiff = now.getTime() - createdDateObj.getTime();

                    // Handle future dates
                    if (timeDiff < 0) {
                        return "just now";
                    }

                    const seconds = Math.floor(timeDiff / 1000);
                    const minutes = Math.floor(seconds / 60);
                    const hours = Math.floor(minutes / 60);
                    const days = Math.floor(hours / 24);

                    // Check conditions in ascending order of time units
                    if (seconds < 60) {
                        return "just now";
                    }

                    if (minutes < 60) {
                        return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
                    }

                    if (hours < 24) {
                        return hours === 1 ? "an hour ago" : `${hours} hours ago`;
                    }

                    if (days > 30) {
                        const day = createdDateObj.getDate();
                        const month = createdDateObj.toLocaleString('default', { month: 'short' });
                        const year = createdDateObj.getFullYear().toString().slice(-2);
                        return `${day} ${month}, ${year}`;
                    }

                    return days === 1 ? "a day ago" : `${days} days ago`;
                };

                return getRelativeTime(selectedDate);
            },
            meta: {
                align: 'center'
            }
        }),
        columnHelper.display({
            id: 'view',
            header: () => "View",
            cell: cellProps => {
                let processing: boolean = cellProps.row.original.is_processing;

                if (processing) {

                    return (
                        <LinkButton
                            linkTo={`/guest-post-finder/view/${cellProps.row.original.id}/`}
                            text={"Generating..."}
                            type={"primary"}
                            width={"100px"}
                            outlined={true}
                            additionalClassList={["is-small", "more-rounded-borders"]}
                        />
                    );
                }
                else {
                    return (
                        <LinkButton
                            linkTo={`/guest-post-finder/view/${cellProps.row.original.id}/`}
                            text={"View"}
                            type={"success"}
                            width={"100px"}
                            additionalClassList={["is-small", "more-rounded-borders"]}
                        />
                    );
                }
            },
            enableGlobalFilter: false,
            meta: {
                align: 'center'
            }
        }),
    ]

    // Creative Logic Implemented on keyword Click
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        errorAlertRef.current?.close();
        // Check if the topic is empty
        if (!topic || !limit) {
            return;
        }
        try {
            const response = await makeApiRequest(
                `/api/frontend/post-guest-post-finder-query/`,
                'post',
                {
                    query: topic,
                    limit: limit
                }
            );

            const data = response.data;
            if (data.success) {
                successAlertRef.current?.show('Query submitted. It will take few minutes to complete.');
                refetch()
            } else {
                if (data.reason === "max_limit_reached") {
                    errorAlertRef.current?.show('You have reached your maximum limit for this plan. Please Upgrade !');
                }
                else {
                    errorAlertRef.current?.show('Failed to fetch queries from backend API.');
                }
            }
        } catch (err) {
            errorAlertRef.current?.show('An error occurred while fetching GPF queries.');
        }
    }

    const handleProceedClick = async (e) => {
        const updatedLimit = maxGpfAllowed - gpfQueriesGenerated;
        setLimit(updatedLimit); // Update limit
        setShowWarning(false); // Close the modal

        e.preventDefault();
        errorAlertRef.current?.close();
        // Check if the topic is empty
        if (!topic || !limit) {
            return;
        }
        try {
            const response = await makeApiRequest(
                `/api/frontend/post-guest-post-finder-query/`,
                'post',
                {
                    query: topic,
                    limit: updatedLimit
                }
            );

            const data = response.data;
            if (data.success) {
                successAlertRef.current?.show('Query submitted. It will take few minutes to complete.');
                refetch()
            } else {
                if (data.reason === "max_limit_reached") {
                    errorAlertRef.current?.show('You have reached your maximum limit for this plan. Please Upgrade !');
                }
                else {
                    errorAlertRef.current?.show('Failed to fetch queries from backend API.');
                }
            }
        } catch (err) {
            errorAlertRef.current?.show('An error occurred while fetching gpf queries.');
        }
    };

    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Find Blogs with Guest Post
                            Opportunities for your Topic/Niche</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <>
                <div className="card w-100">
                    <div className={"card-content"}>
                        <div className={"w-100 is-flex is-align-items-center is-flex-direction-column mt-3"}>
                            <h1 style={{ fontSize: "2em", fontWeight: "normal" }}>
                                Find Blogs with Guest Post Opportunities for your Topic/Niche
                            </h1>
                        </div>
                        <div className="{w-25 is-flex is-align-items-center is-flex-direction-column my-3}">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent the default form submission behavior
                                    if (
                                        limit > maxGpfAllowed ||
                                        (maxGpfAllowed - gpfQueriesGenerated < limit && maxGpfAllowed - gpfQueriesGenerated > 0)
                                    ) {
                                        setShowWarning(true);
                                    } else {
                                        handleSubmit(e);
                                    }
                                }}>
                                <label className="ca-label">Topic</label>
                                <input
                                    className="ca-input"
                                    type="text"
                                    placeholder="Enter topic"
                                    value={topic}
                                    onChange={(e) => setTopic(e.target.value)}
                                    style={{ marginTop: '3px', textAlign: 'start' }}
                                    required
                                />
                                <label className="ca-label">How many opportunities do you want to
                                    find? </label>
                                <span className="text-muted"> (It should be between 10-400)</span><br />
                                <input
                                    className="ca-input"
                                    type="number"
                                    placeholder="Enter limit"
                                    value={limit}
                                    onChange={(e) => setLimit(parseInt(e.target.value))}
                                    style={{ marginTop: '3px', textAlign: 'start' }}
                                    required
                                />

                                <button type={"submit"} className="button is-success"
                                    disabled={isFetching || !pageData.verified || queries.some((query) => query.query === topic && query.is_processing)}>PROCEED</button>
                            </form>
                        </div>
                        <AbunTable tableContentName={"Guest Post Queries"}
                            tableData={queries}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={"No Queries data available."}
                            searchboxPlaceholderText={"Search query..."}
                        />
                        <SuccessAlert ref={successAlertRef} />
                        <ErrorAlert ref={errorAlertRef} />

                        {/************************* Warning Alert ***************************/}
                        <AbunModal
                            active={showWarning}
                            headerText={"Usage Limit Alert"}
                            closeable={false}
                            hideModal={() => setShowWarning(false)}>
                            <div>
                                <p className={"has-text-centered mt-4"}>
                                    You have only {maxGpfAllowed - gpfQueriesGenerated} searches left in your account.<br />Do you want to proceed?
                                </p>
                                <div className={"mt-6 has-text-centered is-flex is-justify-content-center is-align-items-center"}>
                                    <AbunButton type={"danger"} clickHandler={handleProceedClick}>PROCEED</AbunButton>
                                    <AbunButton type={"primary"} className={"ml-4"} clickHandler={() => setShowWarning(false)}>CANCEL</AbunButton>
                                </div>
                            </div>
                        </AbunModal>

                    </div>
                </div>
            </>
        );
    }
}

import { Link } from "react-router-dom";
import SuccessNavbar from "../../components/SuccessNavbar/SuccessNavbar";
import { pageURL } from "../routes";


import "./WordpressSuccess.scss";

export default function WordpressSuccess() {
	return (
		<div className={"wp-success-container"}>
			<SuccessNavbar />
			<div className={"card card-contain"}>
				<div className={"check-icon"}>✅</div>
				<h2 className={"has-text-centered has-text-weight-bold"}>WordPress Integration Successful!</h2>
				<p className={"has-text-centered mt-3"}>
					You can now start posting generated articles on your site through Abun individually<br />
					or by using our Auto Publish feature.
				</p>
				<div className={"btns"}>
					<Link to={pageURL['createArticle']} className={"button is-primary mt-5"}>Create Article</Link>
				</div>
			</div>
		</div>

	)
} 
import AbunLoader from "../../components/AbunLoader/AbunLoader"

interface ConnectWebsiteLoadingProps {
	text: string
}

export default function ConnectWebsiteLoading(props: ConnectWebsiteLoadingProps) {
	return (
		<>
			<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
				<AbunLoader show={true} height="20vh" />
			</div>
			<p className={"is-size-4"}>{props.text}</p>
		</>
	)
}

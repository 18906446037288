import NewSidebar, { UserWebsite } from "../../components/NewSideBar/NewSidebar";

import { useEffect, useRef, useState } from "react";
import { Link, Outlet, useLoaderData, useLocation, useNavigation } from "react-router-dom";
import MessageBox from "../../components/MessageBox/MessageBox";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import "./Base.scss";
// import ContentPlanProgressBar from "../../components/ProgressBar/ContentPlanProgressBar";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import { pageURL } from "../routes";

export interface BasePageData {
	// For sidebar props
	active_website_domain: string | null
	active_website_logo: string | null
	website_list: Array<UserWebsite>
	// For account verification message
	user_verified: boolean
	// User's Current active Plan Name
	currentPlanName: string
	competitor_length: number
	user_has_ltd_plans: boolean
	// For Survey
	survey_completed: boolean
	user_id: number
}

export default function Base() {
	// -------------------------- PAGE DATA --------------------------
	const pageData = useLoaderData() as BasePageData;

	// -------------------------- STATES --------------------------
	const [basePath, setBasePath] = useState<string | null>(null);
	const [userEmailVerified, setUserEmailVerified] = useState(pageData.user_verified);
	const [showCollapsedSidebar, setShowCollapsedSidebar] = useState<boolean>(false);
	const [showSidebar, setShowSidebar] = useState(true);

	const navigation = useNavigation()

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	// -------------------------- EFFECTS --------------------------
	let location = useLocation();
	useEffect(() => {
		try {
			setBasePath(location.pathname.split("/")[1]);
		} catch (e) {
			setBasePath(null);
		}

		if (location.pathname.includes("/articles/edit/")) {
			setShowSidebar(false);
		} else if (location.pathname.includes("/glossary-content")) {
			setShowSidebar(false);
		}
		else {
			setShowSidebar(true);
		}
	}, [location]);


	// for detecting email verification and removing Email not verified alert
	useEffect(() => {
		function handleStorageChange() {
			setUserEmailVerified(true); // set userEmailVerified to true when something is set in the sessionStorage in email verification tab(user-email-verified : true);
		};

		window.addEventListener('storage', handleStorageChange);

		// Clean up the event listener
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);


	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 769) {
				setShowCollapsedSidebar(false);
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		// clean up func
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	// ===============================================================
	// -------------------------- MAIN CODE --------------------------
	// ===============================================================
	document.getElementsByTagName('html')[0].classList.add('base-html');
	document.body.classList.add('base-body');

	return (
		<>
			{
				showSidebar ?
					<>
						<ProgressBar show={navigation.state === "loading"} />
                        <NewSidebar
                            activeWebsiteDomain={pageData.active_website_domain}
                            activeWebsiteLogo={pageData.active_website_logo}
                            websiteList={pageData.website_list}
                            basePath={basePath}
                            hasLtdPlan={pageData.user_has_ltd_plans}
                            showCollapsedSidebar={showCollapsedSidebar}
                            currentPlanName={pageData.currentPlanName}
                            setShowCollapsedSidebar={setShowCollapsedSidebar}
                            successAlertRef={successAlertRef}
                            failAlertRef={failAlertRef}
                        />
						<div className={`main-container ${showCollapsedSidebar ? "expanded" : ""}`}>
							{(!pageData.user_verified && !userEmailVerified) && <MessageBox title={"Email not Verified"}
								type={"warning"}
								blackBodyText={true}
								style={{ maxWidth: "100%" }}>
								📬 <b>Verify your email</b> to start Creating Articles. Not able to find email? Resend email from the <Link to={pageURL['profile']} className={"has-text-weight-bold"}>Profile</Link>.
							</MessageBox>}
							<Outlet />
							{/* <ContentPlanProgressBar /> */}
						</div>
					</> : <Outlet />
			}
			<SuccessAlert ref={successAlertRef} />
			<ErrorAlert ref={failAlertRef} />
		</>
	)
}


import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import SuccessNavbar from "../../components/SuccessNavbar/SuccessNavbar";

import { accountEmailVerificationQuery } from "../../utils/api";
import AbunLoader from "../../components/AbunLoader/AbunLoader";
import { pageURL } from "../routes";

import { useEffect } from "react";
import "./AccountEmailVerification.scss";

export default function AccountEmailVerification() {
	// --------------------- QUERY PARAMETERS ---------------------
	const { token } = useParams();

	// --------------------- QUERY ---------------------
	const { isLoading, error } = useQuery(accountEmailVerificationQuery(token));

	useEffect(() => {
		if (!isLoading && !error) {
			// set user-email-verified in session storage on successful verification
			sessionStorage.setItem("user-email-verified", "true");
			// console.log("set user-email-verified in session storage");
		}
	}, [isLoading, error]);


	if (isLoading) {
		return (
			<div className={"account-email-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
								<AbunLoader show={isLoading} height="20vh" />
							</div>
							<h2 className={"is-size-4 has-text-centered"}>Verifying your account. Please Wait...</h2>
						</div>
					</div>
				</div>
			</div>
		)

	} else if (error) {
		return (
			<>
				{/* ----------------------- NAVBAR ----------------------- */}
				<div className={"account-email-container"}>
					<div className={"card"}>
						<div className={"card-content"}>
							<div className={"content success-card-content"}>
								<p className={"has-text-centered"}>Oops! Something went wrong :(</p>
								<p className={"has-text-danger has-text-centered mt-4"}>Make sure the verification link is correct. If you feel this is incorrect, please contact us via live chat.</p>
							</div>
						</div>
					</div>
				</div>
			</>
		)

	} else {
		return (
			<div className={"account-email-verification-container"}>
				<SuccessNavbar />
				<div className={"card card-contain"}>
					<div className={"check-icon"}>✅</div>
					<h2 className={"has-text-centered has-text-weight-bold"}>You are all set to double your Organic Traffic!</h2>
					<p className={"has-text-centered mt-3"}>
						Your email has been verified.
					</p>
					<div className={"btns"}>
						<Link to={pageURL['keywordResearch']} className={"button is-primary mt-5"}>Proceed to Abun</Link>
					</div>
				</div>
			</div>
		)
	}
}

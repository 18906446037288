/* eslint-disable react-hooks/exhaustive-deps */
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
	ColumnDef,
	FilterFn,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	PaginationState,
	RowData, RowModel,
	SortingState,
	useReactTable,
} from "@tanstack/react-table";
import React, { forwardRef, HTMLProps, MouseEvent, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { makeApiRequest } from '../../utils/api';
import DelayedInput, { ellipsisLinksData } from "../DelayedInput/DelayedInput";
import Icon from "../Icon/Icon";
import AbunLoader from '../AbunLoader/AbunLoader';

import "./AbunTable.scss";

export interface AbunTableRef {
	refetchData: () => Promise<void>;
}

interface AbunTableBaseProps {
	tableContentName: string
	tableData?: Array<any>
	columnDefs: ColumnDef<any, any>[]
	pageSizes: Array<number>
	initialPageSize: number
	noDataText: string
	enableSorting?: boolean
	id?: string
	hidePagination?: boolean

	// initial sorting state
	defaultSortingState?: Array<{ id: string, desc: boolean }>

	// for searchbox
	searchboxPlaceholderText?: string
	ellipsisEnabled?: boolean
	ellipsisDropDownData?: Array<ellipsisLinksData>

	// for checkboxes
	rowCheckbox?: boolean
	selectedRowsSetter?: (rowModel: RowModel<RowData>) => void

	// for extra button at top of table
	bulkActions?: Array<{ text: string, isDisabled?: boolean, extraClassName?: string }>
	bulkActionsEnabled?: boolean

	applyAction?: (action: string) => void

	filterTabs?: Array<{ name: string, onClick: () => void }>

	selectedTab?: string

	selectedTabInfo?: string

	// for searching
	searchText?: string

	tableName?: string

	tableDescription?: string

	handleRowClick?: (row: any, event?: MouseEvent) => void

	buttons?: Array<{ text: string, type: "primary" | "success" | "secondary" | "danger", clickHandler: () => void, isDisabled?: boolean, extraClassName?: string, iconName?: any, iconClasses?: Array<string> }>

	// for blurring rows
	shouldBlurRow?: (row: any) => boolean;

	// for condition render tag
	isListOfTitlesRoute?: boolean

	// server-side props
	serverSide?: boolean
	apiUrl?: string
	queryParams?: Record<string, string>
	transformResponse?: (data: any) => {
		data: any[],
		total: number
	}
	tableSpace?: string
	GlossaryContentGeneration?: boolean
}

const stringSearchFilter: FilterFn<any> = (row, columnId, value) => {
	let cellValue: string = (row.getValue(columnId) as string | number).toString();
	let cellValueToLowerCase: string = (row.getValue(columnId) as string | number).toString().toLowerCase();
	let cellValueToUpperCase: string = (row.getValue(columnId) as string | number).toString().toUpperCase();

	return cellValue.includes(value) || cellValueToLowerCase.includes(value) || cellValueToUpperCase.includes(value);
}

const AbunTable = forwardRef<AbunTableRef, AbunTableBaseProps>((props, ref) => {
	// --------------------------- STATES ---------------------------
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = React.useState<SortingState>(props.defaultSortingState || []);
	const [data, setData] = useState(() => props.tableData ? [...props.tableData] : []);
	const columns = useMemo<ColumnDef<any, any>[]>(() => props.columnDefs, [
		props.columnDefs
	])
	const [rowSelection, setRowSelection] = React.useState({});
	const [bulkActionsActive, setBulkActionsActive] = React.useState(false);
	const [bulkActionSelected, setBulkActionSelected] = React.useState("");
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [{ pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: props.initialPageSize,
	});

	// --------------------------- REFS ---------------------------
	const isInitialMount = useRef(true);
	const previousQueryParams = useRef(props.queryParams);

	useImperativeHandle(ref, () => ({
		refetchData: async () => {
			if (!props.serverSide) return;

			await fetchData({
				pageIndex: table.getState().pagination.pageIndex,
				pageSize: table.getState().pagination.pageSize,
				sortBy: sorting,
				globalFilter
			});
		}
	}));

	const table = useReactTable({
		data: props.serverSide ? data : props.tableData ? props.tableData : [],
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
		state: {
			globalFilter,
			rowSelection,
			sorting
		},
		globalFilterFn: stringSearchFilter,
		onGlobalFilterChange: setGlobalFilter,
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		initialState: {
			pagination: {
				pageSize: props.initialPageSize
			}
		},
		enableRowSelection: props.rowCheckbox,
		onRowSelectionChange: setRowSelection,
		onStateChange(_) {
			setRowSelection({});
		},
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		enableSorting: props.enableSorting ? true : false,
		manualPagination: props.serverSide,
		manualSorting: props.serverSide,
		manualFiltering: props.serverSide,
		pageCount: props.serverSide ?
			Math.ceil(totalRows / pageSize) :
			undefined,
	});


	const fetchData = async (params: {
		pageIndex: number
		pageSize: number
		sortBy: SortingState
		globalFilter: string
	}) => {
		if (!props.apiUrl) return;

		setIsDataLoading(true);

		try {
			const queryParams = new URLSearchParams({
				page: (params.pageIndex + 1).toString(),
				per_page: params.pageSize.toString(),
				...(params.globalFilter && { search: params.globalFilter }),
				...(params.sortBy.length > 0 && {
					sort: params.sortBy.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
				}),
				...props.queryParams
			});

			const response = await makeApiRequest(
				`${props.apiUrl}?${queryParams.toString()}`,
				"get"
			);

			if (!response.status) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const rawData = await response.data;

			// Transform the response if a transform function is provided
			const transformedData = props.transformResponse
				? props.transformResponse(rawData)
				: rawData;

			setData(transformedData.data);
			setTotalRows(transformedData.total);
		} catch (error) {
			console.error('Error fetching data:', error);
			setData([]);
			setTotalRows(0);
		} finally {
			setIsDataLoading(false);
		}
	};

	const fetchParams = useMemo(() => ({
		pageIndex: table.getState().pagination.pageIndex,
		pageSize: table.getState().pagination.pageSize,
		sortBy: sorting,
		globalFilter
	}), [
		table.getState().pagination.pageIndex,
		table.getState().pagination.pageSize,
		sorting,
		globalFilter
	]);

	useEffect(() => {
		if (!props.serverSide) {
			setData(props.tableData ? [...props.tableData] : []);
		}
	}, [props.tableData]);

	useEffect(() => {
		// on rowSelection change, update selected rows
		if (props.selectedRowsSetter) {
			props.selectedRowsSetter(table.getSelectedRowModel());
			setBulkActionsActive(Object.keys(rowSelection).length > 0);
		}
	}, [rowSelection]);

	useEffect(() => {
		if (props.GlossaryContentGeneration) {
			setBulkActionsActive(true);
		}
	}, []);

	useEffect(() => {
		if (props.searchText) {
			setGlobalFilter(props.searchText);
		}
	}, [props.searchText]);

	useEffect(() => {
		if (!props.serverSide) return;

		// Initial mount
		if (isInitialMount.current) {
			isInitialMount.current = false;
			fetchData(fetchParams);
			return;
		}

		// Check if queryParams actually changed
		if (JSON.stringify(previousQueryParams.current) !== JSON.stringify(props.queryParams)) {
			previousQueryParams.current = props.queryParams;
			fetchData(fetchParams);
		}
	}, [props.serverSide, props.queryParams]);

	useEffect(() => {
		if (!props.serverSide || isInitialMount.current) return;

		const timer = setTimeout(() => {
			fetchData(fetchParams);
		}, 300);

		return () => clearTimeout(timer);
	}, [fetchParams]);

	/**
	 * Returns JSX element with no data text if one of the no data conditions is met. Otherwise returns empty JSX element.
	 * Conditions:
	 * 1. Table data receied through props was empty (ex. no articles in db).
	 * 2. Number of rows in Tanstack Table object is 0 (ex. no filter matches).
	 */
	function getNoDataTextElement() {
		if (isDataLoading) {
			return <></>
		} else if (globalFilter && table.getRowModel().rows.length <= 0) {
			return (
				<div className={"abun-table-no-data"}>
					No data matching this search query :(<br />
					Clear the searchbox or try using a different search text.
				</div>
			)

		} else if (table.getRowModel().rows.length <= 0) {
			return (
				<div className={"abun-table-no-data"}>
					{props.noDataText}
				</div>
			)
		} else {
			return <></>
		}
	}

	function handlePageNumberChange(newPageNum: string) {
		let num: number;

		try {
			// if user provided page number exceeds total pages, cap it to last page number;
			num = parseInt(newPageNum);
			if (num > table.getPageCount()) {
				num = table.getPageCount();
			} else if (num < 1) {
				num = 1;
			}
		} catch (e) {
			num = 1;
		}

		// -1 because page index starts at 0
		table.setPageIndex(num - 1);
	}
	function goToPrevPage() {
		table.setPageIndex(table.getState().pagination.pageIndex - 1);
	}

	function goToNextPage() {
		table.setPageIndex(table.getState().pagination.pageIndex + 1)
	}

	function changePageSize(newSize: string) {
		let newSizeNumber: number;

		try {
			newSizeNumber = parseInt(newSize);
		} catch (e) {
			newSizeNumber = 1;
		}

		if (newSizeNumber <= 0) newSizeNumber = 1;

		table.setPageSize(newSizeNumber);
		setPagination(prev => ({
			pageIndex: prev.pageIndex,
			pageSize: newSizeNumber
		}));
	}

	return (
		<div className={"abun-table-responsive"} id={props.id}>
			<div className={"abun-table-container abun-table-container--fullwidth"}>
				{props.tableName &&
					<h1 className="abun-table-title w-100 has-text-centered is-size-3" style={{ fontWeight: "800" }}>
						{props.tableName}
					</h1>
				}
				{props.tableDescription &&
					<p className="abun-table-description w-100 has-text-centered is-size-5">
						{props.tableDescription}
					</p>
				}
				{props.filterTabs &&
					<div className="abun-table-filter-buttons is-flex is-justify-content-space-between table-filter-button-width" style={{ width: "623px", margin: "auto", marginBottom: "1rem", marginTop: "1rem", position: "sticky" }}>
						<div className="btns-container">
							{props.filterTabs?.map((tab, index) => (
								<button key={index}
									className={`filter-button button w-100 has-text-centered is-size-7 cursor-pointer is-button ${props.selectedTab === tab.name ? "active" : ""}`}
									onClick={tab.onClick}>
									{tab.name}
								</button>
							))}
						</div>
						{props.selectedTabInfo && <p className="selected-tab-info">
							{props.selectedTabInfo}
						</p>}
					</div>
				}
				{(props.buttons || props.bulkActions || props.searchboxPlaceholderText) &&
					<div className="abun-table-content" style={{
						display: "flex",
						justifyContent: props.isListOfTitlesRoute ? "unset" : "space-between", // Add conditional justify-content
						alignItems: "center"
					}}>
						{
							!props.buttons && !props.bulkActions &&
							<div className="balancer" />
						}
						{
							props.buttons &&
							<div className={"abun-table-button-container"}>
								{props.buttons?.map((button, index) => (
									<button key={index}
										className={`button is-${button.type} ${button.extraClassName} is-flex is-align-items-center`}
										onClick={button.clickHandler}
										disabled={button.isDisabled}>
										{button.iconName && <Icon iconName={button.iconName} additionalClasses={button.iconClasses} />}
										<p className={"btn-txt"}>{button.text}</p>
									</button>
								))}
							</div>
						}
						{props.bulkActions && (props.bulkActionsEnabled === undefined ? true : props.bulkActionsEnabled) &&
							// If no bluk actions are provided i.e. no checkboxes are checked, then don't show the dropdown
							props.bulkActions.length > 0 &&
							<div className="abun-table-dropdown-container is-flex is-justify-content-space-between is-align-items-center">
								<FormControl sx={{ mx: 1, mt: -0.3, minWidth: 112, maxHeight: 35, fontSize: "0.75rem" }} size="small">
									<InputLabel id="bulk-action-dropdown-label">Bulk Actions</InputLabel>
									<Select
										labelId="bulk-action-dropdown-label"
										id="bulk-action-dropdown"
										//value={age}
										label="Bulk Actions"
										onChange={(e: SelectChangeEvent) => {
											setBulkActionSelected(e.target.value);
										}}
									>
										{props.bulkActions?.length === 0 && (
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
										)}
										{props.bulkActions?.map((action, index) => (
											<MenuItem sx={{ fontSize: "1rem" }} key={index} value={action.text}>{action.text}</MenuItem>
										))}
									</Select>
								</FormControl>
								<button className={`abun-table-dropdown-apply-btn button is-tag is-flex is-align-items-center is-justify-content-center w-25 ${(bulkActionSelected && bulkActionsActive) ? "active" : ""}`}
									onClick={() => { if (bulkActionSelected) props.applyAction?.(bulkActionSelected) }}
								>
									Apply
								</button>
							</div>
						}

						{props.searchboxPlaceholderText && <DelayedInput initialValue={globalFilter ?? ''}
							delayInMilliseconds={500}
							placeholder={props.searchboxPlaceholderText}
							// center the searchbox
							additionalClasses={['abun-table-search-input']}
							resetInput={undefined}
							onChange={value => {
								setGlobalFilter(String(value));
							}} />}
						<div className="balancer" />
					</div>
				}
				<table className={"table is-fullwidth is-hoverable"} style={{ tableLayout: props.tableSpace === "fixed" ? "fixed" : "auto" }}>
					<thead>
						{table.getHeaderGroups().map(headerGroup => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<th key={header.id} style={{ minWidth: (header.id === headerGroup.headers[0].id && headerGroup.headers.length === 2) ? "18rem" : undefined }}>
										{header.isPlaceholder ? null : (
											<div
												className={`has-text-centered is-flex is-align-item-center is-justify-content-center ${header.column.getCanSort() ? "sortable-column" : "unsortable-column"} ${(header.column.columnDef?.meta as any)?.align === "center" ? "mx-auto" : ""}`}
												onClick={header.column.getToggleSortingHandler()}
											>
												{
													header.column.getCanSort() &&
													<div className={`sort-icon-container ${header.column.getIsSorted() === false ? "not-sorted" : header.column.getIsSorted() === "asc" ? "ascending" : "descending"}`}>
														<span className={"upward-arrow is-block"}>▲</span>
														<span className={"downward-arrow is-block"}>▼</span>
													</div>
												}
												{flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
											</div>
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{isDataLoading ? (
							<tr>
								<td colSpan={columns.length} className="has-text-centered">
									<div style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										padding: "2rem"
									}}>
										<AbunLoader show={isDataLoading} height="20vh" />
									</div>
								</td>
							</tr>
						) : (
							table.getRowModel().rows.map(row => (
								props.shouldBlurRow?.(row.original) ?
									<tr className={'blurred-row'}>
										{row.getVisibleCells().map(cell => (
											<td align={(cell.column.columnDef.meta as any)?.align}>
												Upgrade to unlock.
											</td>
										))}
									</tr>
									:
									<tr key={row.id} onClick={(e) => {
										// avoid calling handleRowClick if the click was on a child element that has its own click handler
										if ((e.target as HTMLElement).tagName !== "TD") return;
										if ((e.target as HTMLElement).closest("button, a, input")) return;
										props.handleRowClick?.(row.original, e);
									}} style={{ cursor: props.handleRowClick ? "pointer" : "default" }}>
										{row.getVisibleCells().map(cell => (
											<td key={cell.id} align={(cell.column.columnDef.meta as any)?.align}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										))}
									</tr>
							))
						)}
					</tbody>
				</table>
				{getNoDataTextElement()}
				{props?.hidePagination !== true &&
					<div className={"abun-table-pagination-controls-container"}>
						<div className={"abun-table-pagination-size-select"}>
							Total <b>{props.serverSide ? totalRows : props.tableData?.length}</b> {props.tableContentName}&nbsp;&nbsp;|&nbsp;&nbsp;Show&nbsp;
							<div className="select is-small">
								<select defaultValue={props.initialPageSize} onChange={e => changePageSize(e.target.value)}>
									{props.pageSizes.map(size => (
										<option key={size} value={size}>{size}</option>
									))}
								</select>
							</div>&nbsp; entries per page
						</div>
						<div className={"abun-table-pagination-page-control"}>
							<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={table.getCanPreviousPage() ? {
								transform: "rotate(180deg)"
							} : { display: "none" }} onClick={goToPrevPage}>
								<path d="M1 1L8 7.825L1.66667 14" stroke="#929292" strokeWidth="2" strokeLinecap="round" />
							</svg>
							<div className={"page-input-container"}>
								<DelayedInput initialValue={(table.getState().pagination.pageIndex + 1).toString()}
									delayInMilliseconds={500}
									resetInput={undefined}
									onChange={handlePageNumberChange}
									additionalClasses={["page-input"]} />
								&nbsp;/&nbsp;{table.getPageCount()}
							</div>
							<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={table.getCanNextPage() ? {} : { display: "none" }} onClick={goToNextPage}>
								<path d="M1 1L8 7.825L1.66667 14" stroke="#929292" strokeWidth="2" strokeLinecap="round" />
							</svg>
						</div>
					</div>
				}
			</div>
		</div>
	)
})

export function IndeterminateCheckbox(
	{ indeterminate, className = '', ...rest }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>
) {
	const ref = React.useRef<HTMLInputElement>(null!)
	React.useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate])

	return (
		<input
			type="checkbox"
			ref={ref}
			className={className + ' cursor-pointer mt-1'}
			{...rest}
		/>
	)
}

AbunTable.displayName = 'AbunTable';

export default AbunTable;

import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import articleIntegrationIcon from "../../assets/images/article-integration-logo.png";
import shopifyIconPrimary from "../../assets/images/Shopify-logo-primary.png";
import webflowIconPrimary from "../../assets/images/webflow-logo-primary.png";
import wixIconPrimary from "../../assets/images/wix-logo-primary.png";
import wordpressIconPrimary from "../../assets/images/wordpress-logo-primary.png";
import wordpressIconSuccess from "../../assets/images/wordpress-logo.png";
import AbunButton from '../../components/AbunButton/AbunButton';
import AbunModal from '../../components/AbunModal/AbunModal';
import AbunTable, { IndeterminateCheckbox } from '../../components/AbunTable/AbunTable';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import Icon from '../../components/Icon/Icon';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import { archiveBulkGlossaryMutation, getGlossaryContentMutation, getTaskProgress, postBulkGlossaryMutation, postGlossaryBlogMutation, postGlossaryContentMutation, resumeGlossaryContentMutation, updateGlossaryMutation } from '../../utils/api';
import { AllIntegrations } from '../Articles/ShowV2Articles';
import { CustomContextMenu } from '../KeywordsResearchV2/KeywordResearch';
import { pageURL } from '../routes';
import AbunLoader from '../../components/AbunLoader/AbunLoader';
import './Glossary.scss';

interface GlossaryData {
  word: string;
  glossary_words: string[];
  current_plan_name: string;
  flag: boolean;
  all_integrations_with_unique_id: AllIntegrations[];
}
interface GlossaryWords {
  term: string;
}


const integrationLogoPrimary = {
  "wordpress": wordpressIconPrimary,
  "webflow": webflowIconPrimary,
  "wix": wixIconPrimary,
  "shopify": shopifyIconPrimary
}

const integrationLogoSuccess = {
  "wordpress": wordpressIconSuccess,
}


const GlossaryTopic: React.FC = () => {
  // ----------------------- NON STATE CONSTANTS -----------------------
  const pageSizes = [5, 10, 15, 30, 50, 100, 500];

  // ----------------------- STATE VARIABLES -----------------------
  const { projectId } = useParams();
  const [glossaryWords, setGlossaryWords] = useState<string[]>([]);
  const [customTitle, setCustomTitle] = useState("");
  const [showCreateCustomTitleModal, setShowCreateCustomTitleModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingContent, setLoadingContent] = useState(false);
  const [termsWithKeywordHash, setTermsWithKeywordHash] = useState<Map<string, string>>(new Map());
  const [termsWithGlossaryLink, setTermsWithGlossaryLink] = useState<Map<string, string>>(new Map());
  const [termsWithGlossaryPosted, setTermsWithGlossaryPosted] = useState<Map<string, boolean>>(new Map());
  const [termsWithGlossaryArchived, setTermsWithGlossaryArchived] = useState<Map<string, boolean>>(new Map());
  const [requestModalActive, setRequestModalActive] = useState(false);
  const [requestGlossaryModalActive, setRequestGlossaryModalActive] = useState(false);
  const [projectTaskMap, setProjectTaskMap] = useState<Map<string, string>>(new Map());
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [IsUpgrade, setIsUpgrade] = useState(false);
  const [modalText, setModalText] = useState("");
  const [integrationWithUniqueID, setIntegrationWithUniqueID] = useState<Array<AllIntegrations>>([]);
  const [selectedIntegrationUniqueID, setSelectedIntegrationUniqueID] = useState("");


  const [bulkActionsEnabled, setBulkActionsEnabled] = useState(false);
  const allBulkOptions = useMemo(() => [
    {
      text: "Publish",
    },
    {
      text: "Archive",
    },
    {
      text: "Unarchive",
    },
  ], []);
  const [availableBulkActions, setAvailableBulkActions] = useState(allBulkOptions);
  const [selectedRows, setSelectedRows] = useState<Map<string, string>>(new Map());
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [selectedTab, setSelectedTab] = useState("Generated Glossaries");
  const tableRef = useRef<{ refetchData: () => Promise<void> }>(null);
  const [openDropdown, setOpenDropdown] = useState("");
  const [showApplyModel, setShowApplyModel] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");


  const navigate = useNavigate();
  const pageData = useLoaderData() as GlossaryData;
  const updateGlossary = useMutation(updateGlossaryMutation);
  const postGlossaryContent = useMutation(postGlossaryContentMutation);
  const getGlossaryContent = useMutation(getGlossaryContentMutation);
  const resumeGlossaryContent = useMutation(resumeGlossaryContentMutation);

  const postSelectedGlossaries = useMutation(postBulkGlossaryMutation);
  const archiveSelectedGlossaries = useMutation(archiveBulkGlossaryMutation);
  const postGlossary = useMutation(postGlossaryBlogMutation);

  // --------------------------- Refs ---------------------------
  const successAlertRef = useRef<any>(null);
  const errorAlertRef = useRef<any>(null);


  function goBack() {
    navigate(-1);
  }
  function goBackModal() {
    setRequestGlossaryModalActive(false);
  }

  useEffect(() => {
    if (pageData && pageData.glossary_words) {
      setGlossaryWords(pageData.glossary_words); // Set glossary words from pageData
      setCurrentPlanName(pageData.current_plan_name);
      setIsUpgrade(pageData.flag);
      setIntegrationWithUniqueID(pageData.all_integrations_with_unique_id);
    }
    setLoading(false);
  }, [pageData]);

  // Filter terms based on selectedTab
  const filteredTerms = selectedTab === "Published Glossaries"
    ? glossaryWords.filter((word) => termsWithGlossaryPosted.get(word) === true)
    : selectedTab === "Archived Glossaries"
      ? glossaryWords.filter((word) => termsWithGlossaryArchived.get(word) === true)
      : glossaryWords;

  // ----------------------- TRANSFORM GLOSSARY WORDS -----------------------
  const glossaryWordsWithTerm = filteredTerms
    .sort((a, b) => a.localeCompare(b)) // Sort terms A-Z
    .map((term) => ({ term }));

  const fetchGlossaryContent = async () => {
    if (!projectId) {
      return;
    }
    setLoading(true); // Set loading state to true while fetching data
    try {
      const response = await getGlossaryContent.mutateAsync(projectId); // Mutate with projectId
      const data = response.data;

      const updatedTerms = new Map();
      const updatedGlossaryLinks = new Map();
      const updatedGlossaryPosted = new Map();
      const updatedGlossaryArchived = new Map();

      data?.glossary_terms.forEach((termObj) => {
        updatedTerms.set(termObj.term, termObj.keyword_hash);
        updatedGlossaryLinks.set(termObj.term, termObj.glossary_link);
        updatedGlossaryPosted.set(termObj.term, termObj.is_posted);
        updatedGlossaryArchived.set(termObj.term, termObj.is_archived);



        // Set the task_id directly from glossary_terms
        const taskId = termObj.task_id;
        if (taskId) {
          setProjectTaskMap(prevMap => new Map(prevMap.set(projectId as string, taskId)));
        }
      });

      setTermsWithKeywordHash(updatedTerms); // Update the state with the glossary terms and their keyword hashes
      setTermsWithGlossaryLink(updatedGlossaryLinks);
      setTermsWithGlossaryPosted(updatedGlossaryPosted);
      setTermsWithGlossaryArchived(updatedGlossaryArchived);
      setBulkActionsEnabled(true);

      const matchingIntegration = pageData.all_integrations_with_unique_id.find((integration) =>
        integration.integrationName.toLowerCase().includes("wordpress")
      );

      setSelectedIntegrationUniqueID(
        matchingIntegration ? matchingIntegration.integrationUniqueID : ""
      );


    } catch (error) {
      // errorAlertRef.current?.show("Failed to fetch glossary content. Please try again.");
      setTimeout(() => {
        errorAlertRef.current?.close();
      }, 5000);
    } finally {
      setLoading(false); // Set loading state to false after the mutation is done
    }
  };

  useEffect(() => {
    fetchGlossaryContent(); // Call the function to fetch glossary content
  }, []);

  const handleDeleteWord = (word: string) => {
    const updatedGlossaryWords = glossaryWords.filter((item) => item !== word);
    updateGlossary.mutate(
      {
        projectId: projectId as string,
        glossaryWords: updatedGlossaryWords,
        word: pageData.word,
      },
      {
        onSuccess: () => {
          successAlertRef.current?.show(`Term "${word}" deleted successfully!`);
          setTimeout(() => {
            successAlertRef.current?.close();
          }, 5000);
          setGlossaryWords(updatedGlossaryWords); // Update state
        },
        onError: () => {
          errorAlertRef.current?.show("Failed to delete term.");
          setTimeout(() => {
            errorAlertRef.current?.close();
          }, 5000);
        },
      }
    );
  };

  const handleCreateContent = () => {
    setRequestGlossaryModalActive(true);
    setLoadingContent(true);
    postGlossaryContent.mutate(
      { projectId: projectId as string }, // Pass the projectId or other required params
      {
        onSuccess: (response) => {

          const taskId = response.data?.task_id; // Extract the taskId from the response

          if (!taskId) {
            errorAlertRef.current?.show("Task ID not found in response.");
            setTimeout(() => {
              errorAlertRef.current?.close();
            }, 5000);
            return;
          }

          // Start polling the task progress
          pollTaskProgress(taskId);

          // Assuming the response contains a list of terms with their keywordHash
          const data = response.data;
          const updatedTerms = new Map();
          data?.generated_contents.forEach((termObj) => {
            updatedTerms.set(termObj.term, termObj.keyword_hash);
          });

          setTermsWithKeywordHash(updatedTerms);
        },
        onError: (error) => {
          setTimeout(() => {
            errorAlertRef.current?.close();
          }, 5000);
        },
        onSettled: () => {
          setLoadingContent(false);
        },
      }
    );
  };

  const pollTaskProgress = (taskId) => {
    const interval = setInterval(() => {
      getTaskProgress(taskId)
        .then((res) => {
          const status = res.data.status;

          // Handle task completion or failure
          if (status === "success") {
            clearInterval(interval);
            setRequestGlossaryModalActive(false);
            setIsUpgrade(true);
            successAlertRef.current?.show("Glossary content created successfully!");
            setTimeout(() => successAlertRef.current?.close(), 5000);

            fetchGlossaryContent();
          } else if (status === "failure" || status === "FAILURE") {
            clearInterval(interval);
            setRequestGlossaryModalActive(false);
            errorAlertRef.current?.show("Task failed. Please try again.");
            setTimeout(() => errorAlertRef.current?.close(), 5000);
          }
        })
        .catch((err) => {
          console.error("Error fetching task progress:", err);
          clearInterval(interval);
          setRequestGlossaryModalActive(false);
          errorAlertRef.current?.show("Error fetching task progress. Please try again.");
          setTimeout(() => errorAlertRef.current?.close(), 5000);
        });
    }, 2000); // Poll every 2 seconds
  };

  const handleRetryTask = () => {
    if (!projectId || !projectTaskMap.has(projectId)) {
      errorAlertRef.current?.show("No task ID available for retry for this project.");
      setTimeout(() => errorAlertRef.current?.close(), 5000);
      return;
    }

    const taskId = projectTaskMap.get(projectId);

    setRequestGlossaryModalActive(true); // Show the modal for retry
    setLoadingContent(true); // Indicate loading during the retry process

    resumeGlossaryContent.mutate(
      { taskId: taskId as string }, // Pass the required parameters
      {
        onSuccess: (response) => {

          // Resume polling the task progress
          pollTaskProgress(taskId);
        },
        onError: (error) => {
          console.error("Failed to resume task:", error.response.data.error);
          // Check if the error has a response with an error message
          if (error.response.data.error) {
            // Show the error message from the backend
            errorAlertRef.current?.show(error.response.data.error);
          } else {
            // Fallback error message if no specific error is provided
            errorAlertRef.current?.show("Failed to resume task. Please try again.");
          }
          setRequestGlossaryModalActive(false);
          setTimeout(() => errorAlertRef.current?.close(), 5000);
        },
        onSettled: () => {
          setLoadingContent(false); // Reset loading state
        },
      }
    );
  };

  function postToBlogHandler(keywordHash: string, updatePublishedArticle: boolean = false) {
    errorAlertRef.current?.close();
    successAlertRef.current?.close();

    // show processing modal
    setRequestModalActive(true);
    setModalText(updatePublishedArticle ? "Updating the glossary on your site..." : "Posting glossary to your site...");

    if (keywordHash && selectedIntegration) {
      postGlossary.mutate({
        keywordHash: keywordHash,
        selectedIntegration: selectedIntegration,
        selectedIntegrationUniqueID: selectedIntegrationUniqueID,
        postStatus: 'publish',
        updatePublishedArticle: updatePublishedArticle
      }, {
        onSuccess: (response) => {
          successAlertRef.current?.show(updatePublishedArticle ? `Glossary ${keywordHash} has been successfully updated on your site!` : `Glossary ${keywordHash} was posted to your site successfully!`);
          setRequestModalActive(false);
          setTimeout(() => {
            successAlertRef.current?.close();
          }, 5000);
        },
        onError: () => {
          setRequestModalActive(false);
          errorAlertRef.current?.show(
            "Oops! Something went wrong. Check your Email for more details or contact us if the issue persists."
          );
          setTimeout(() => {
            errorAlertRef.current?.close();
          }, 5000);
        }
      })
    } else {
      setRequestModalActive(false);
      errorAlertRef.current?.show("Key information is missing. Please try again.");
      setTimeout(() => {
        errorAlertRef.current?.close();
      }, 5000);
    }
  }

  // ----------------------- TABLE -----------------------
  const columnHelper = createColumnHelper<GlossaryWords>();

  // Define columns
  const columnDefs: ColumnDef<GlossaryWords, any>[] = [
    columnHelper.accessor((row) => row.term, {
      id: 'term',
      header: "Terms",
      cell: (props) => (
        <span onClick={() => {
          const term = props.row.original.term;
          if (termsWithKeywordHash.has(term)) {
            navigate(`/glossary-content/${termsWithKeywordHash.get(term)}`);
          }
        }}>{props.row.original.term}</span>
      ),
    }),
    columnHelper.display({
      header: () => selectedTab === "Published Glossaries" ? <span>View</span> : <span>Publish</span>,
      id: 'post_article',
      cell: (props) => {
        const term = props.row.original.term;
        const postedTo = "wordpress";
        const filteredIntegrations = integrationWithUniqueID.filter(integration =>
          integration.integrationName.toLowerCase().includes("wordpress")
        );
        if (termsWithGlossaryPosted.get(term)) {
          return <>
            <a className={"view-on-blog-link"} href={termsWithGlossaryLink.get(term)} data-tooltip-id="my-tooltip" target="_blank" rel="noreferrer" data-tooltip-content="View on Blog" >
              <img className={"image"} src={integrationLogoSuccess[postedTo]} alt={"integration-icon"} />
            </a>
            <Tooltip id="my-tooltip" />
          </>
        } else if (termsWithKeywordHash.size > 0 && termsWithKeywordHash.get(term)) {
          return <>
            <div className="publish-container is-flex is-align-items-center is-justify-content-center">
              <button className={"publish-to-blog-btn is-flex is-align-items-center is-justify-content-center"} data-tooltip-id="publish-to-blog" data-tooltip-content={integrationWithUniqueID.length > 0 ? "Publish to Blog" : "Connect & Publish Now"}
                onClick={() => {
                  if (filteredIntegrations.length > 0) {
                    postToBlogHandler(termsWithKeywordHash.has(term) ? termsWithKeywordHash.get(term) ?? "" : "");
                  }
                }}>
                <img className={"image"} src={integrationWithUniqueID.length === 0 ? articleIntegrationIcon : integrationLogoPrimary[getIntegrationName(selectedIntegration)]} alt={"integration-icon"} />
              </button>
              {filteredIntegrations.length > 1 &&
                <div className="dropdown">
                  <button className={`dropdown-icon ${openDropdown === termsWithKeywordHash.get(term) ? "rotate" : ""}`} onClick={() => setOpenDropdown(openDropdown === termsWithKeywordHash.get(term) ? "" : termsWithKeywordHash.get(term) ?? "")}>&#9662;</button>
                  <div className={`dropdown-content ${openDropdown === termsWithKeywordHash.get(term) ? "show" : ""}`}>
                    <h6 className={"dropdown-header"}>
                      Choose Integration
                    </h6>
                    {
                      integrationWithUniqueID.map((integration, index) => (

                        <div key={index} className={"dropdown-item"} onClick={() => {
                          setIntegrationAndHideDropDownContent(integration.integrationName);
                        }}>
                          <input type="radio" name="integration" id={integration.integrationName} checked={selectedIntegration === integration.integrationName} onChange={() => { }} />
                          <p>{truncateSiteDomain(integration.integrationName.charAt(0).toUpperCase() + integration.integrationName.slice(1))}</p>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
            </div>
            <Tooltip id="publish-to-blog" />
          </>
        }
        else {
          return <NavLink to={pageURL['manageSubscription']} className={"upgrade"} >
            <span className="tag is-info is-light is-rounded">
              Upgrade ⚡
            </span>
          </NavLink>
        }
      },
      enableGlobalFilter: false,
      meta: {
        align: 'center'
      }
    }),

    columnHelper.display({
      id: 'action',
      header: () => <span>Action</span>,
      cell: (props) => {
        const term = props.row.original.term;

        return (
          <span>
            {termsWithKeywordHash.has(term) ? (
              <CustomContextMenu
                url={`/glossary-content/${termsWithKeywordHash.get(term)}`}
                CtrlOrMetaClick={() => { }}
                normalClick={() => navigate(`/glossary-content/${termsWithKeywordHash.get(term)}`)}
              >
                <button
                  style={{ width: "100px" }}
                  className="button is-success is-outlined is-small more-rounded-borders"
                >
                  View
                </button>
              </CustomContextMenu>
            ) : (
              <button
                className="delete-btn"
                onClick={() => handleDeleteWord(term)}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_48_5565)">
                    <g clipPath="url(#clip1_48_5565)">
                      <path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_48_5565">
                      <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                    </clipPath>
                    <clipPath id="clip1_48_5565">
                      <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            )}
          </span>
        );
      }
    }),
  ];

  if (bulkActionsEnabled) {
    // Add checkbox if bulk actions are enabled
    columnDefs.splice(0, 0, columnHelper.accessor((row: GlossaryWords) => row.term, {
      id: 'checkbox',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: () => {
              const isAllSelected = table.getIsAllRowsSelected();

              // Update selectedRows state and toggle selection
              const newSelectedRows = new Map();
              if (!isAllSelected) {
                // Select all rows
                table.getRowModel().rows.forEach(row => {
                  const term = row.original.term;
                  const keywordHash = termsWithKeywordHash.get(term);
                  if (keywordHash) {
                    newSelectedRows.set(term, keywordHash);
                  }
                });
              }
              setSelectedRows(newSelectedRows);

              // Trigger table's internal toggle logic
              table.toggleAllRowsSelected(!isAllSelected);
            },
          }}
        />
      ),
      cell: ({ row }) => {
        const term = row.original.term;
        const keywordHash = termsWithKeywordHash.get(term);
        const isChecked = selectedRows.has(term);

        return (
          <IndeterminateCheckbox
            {...{
              checked: isChecked,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                handleCheckboxChange(term, keywordHash || '', e.target.checked);
              },
            }}
            name={"articleSelection"}
            value={keywordHash}
          />
        );
      },
      enableGlobalFilter: true,
      enableSorting: false,
    }));
  } else {
    // Remove the checkbox if bulk actions are disabled
    columnDefs.splice(0, 0);
  }



  function getIntegrationName(integrate: string | undefined) {
    if (!integrate) {
      return "wordpress";
    } else {
      if (integrate.includes("wordpress")) {
        return "wordpress";
      } else if (integrate.includes("webflow")) {
        return "webflow";
      } else if (integrate.includes("shopify")) {
        return "shopify";
      } else {
        return "wix";
      }
    }
  }

  function setIntegrationAndHideDropDownContent(integrate: string) {
    setSelectedIntegration(integrate);
    setOpenDropdown("");
  }
  function truncateSiteDomain(domain: string) {
    if (domain.includes("Webflow")) {
      domain = domain.replace(new RegExp("Webflow - ", 'g'), '');
    } else if (domain.includes("Wix")) {
      domain = domain.replace(new RegExp("Wix - ", 'g'), '');
    } else if (domain.includes("Shopify")) {
      domain = domain.replace(new RegExp("Shopify - ", 'g'), '');
    } else {
      domain = domain.replace(new RegExp("Wordpress - ", 'g'), '');
    }

    if (domain.length <= 20) {
      return domain;
    } else {
      domain = domain.substring(0, 17) + "...";
    }

    return domain;
  }

  useEffect(() => {
    // hide the table column according to the selected tab
    const tab_to_hide = document.querySelector("#article-titles-table .hide-column-child")?.parentElement || document.querySelector("#article-titles-table .hide-column");

    // filter the data according to the selected tab
    if (selectedTab === "Generated Glossaries") {
      const removeOptions = currentPlanName === "Trial" ? ["Unarchive", "Publish"] : ["Unarchive"];
      const newBulkOptions = allBulkOptions.filter(option => !removeOptions.includes(option.text));
      setAvailableBulkActions(newBulkOptions);
      setBulkActionsEnabled(true);

      while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
        tab_to_hide.classList.remove("hide-column");
      }
    } else if (selectedTab === "Published Glossaries") {
      const removeOptions = ["Unarchive", "Publish"];
      const newBulkOptions = allBulkOptions.filter(option => !removeOptions.includes(option.text));
      setAvailableBulkActions(newBulkOptions);
      setBulkActionsEnabled(true);

      while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
        tab_to_hide.classList.remove("hide-column");
      }

    } else if (selectedTab === "Archived Glossaries") {
      setAvailableBulkActions([]);
      setBulkActionsEnabled(false);

      while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
        tab_to_hide.classList.remove("hide-column");
      }

    }
  }, [allBulkOptions, selectedTab]);
  useEffect(() => {
    if (integrationWithUniqueID.length > 0) {
      const firstIntegration = integrationWithUniqueID[0];
      setSelectedIntegration(firstIntegration.integrationName);
    }
  }, [integrationWithUniqueID]);

  const handleBulkActionSuccess = (message: string) => () => {
    setRequestModalActive(false);
    // Use the table ref to trigger refetch
    tableRef.current?.refetchData();
    setSelectedRows(new Map());
    successAlertRef.current?.show(message);
    fetchGlossaryContent();
  };

  const handleBulkActionError = (message: string) => () => {
    setRequestModalActive(false);
    errorAlertRef.current?.show(message);
  };


  const handleBulkAction = (action: string) => {
    setShowApplyModel(false);
    if (action === "Archive") {
      setModalText("Processing Request. Please wait...");
    }
    else {
      setModalText("Posting to your website blog. Please wait...");
    }
    setRequestModalActive(true);
    const keywordHashes: Array<string> = Array.from(selectedRows.values()).filter((hash) => hash.trim() !== "");

    const actions = {
      'Publish': () => postSelectedGlossaries.mutate({
        keywordHashes: keywordHashes,
        selectedIntegration: selectedIntegration
      }, {
        onSuccess: handleBulkActionSuccess("Glossaries was posted to your site successfully!"),
        onError: handleBulkActionError("Failed to post glossaries. Please try again after some time.")
      }),
      'Archive': () => archiveSelectedGlossaries.mutate({
        keywordHashes: keywordHashes,
        archiveType: "archive"
      }, {
        onSuccess: handleBulkActionSuccess("Glossaries are being archived. Please wait for a moment."),
        onError: handleBulkActionError("Failed to archive glossaries. Please try again after some time.")
      }),
    };

    actions[action]?.();
  };

  const handleCheckboxChange = (term: string, keywordHash: string, isChecked: boolean) => {
    setSelectedRows(prevSelectedRows => {
      const newSelectedRows = new Map(prevSelectedRows);
      if (isChecked) {
        newSelectedRows.set(term, keywordHash); // Add to selected rows
      } else {
        newSelectedRows.delete(term); // Remove from selected rows
      }
      return newSelectedRows;
    });
  };
  const getNoDataText = (tab: string) => {
    if (tab === "Generated Glossaries") {
      return "Generated glossaries will appear here.";
    } else if (tab === "Published Glossaries") {
      return "Published glossaries will appear here.";
    } else if (tab === "Archived Glossaries") {
      return "Archived glossaries will appear here.";
    }
    return "No glossaries found.";
  };



  return (
    <div className="glossary-topic-card">
      <span className={"back-btn"} onClick={goBack}>
        <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
        </svg>
      </span>
      <h1 className={"is-size-4"} style={{ color: 'blue' }}>Choose Glossary Term → Generate Content → Publish Full Glossary</h1>
      <p className={"is-size-5 mt-4"} style={{ color: 'green' }}>Topic : {pageData.word || 'NA'}</p>
      <p className="count-glossary">{glossaryWords.length || 0} Total Glossary Terms Keywords</p>
      {(!termsWithKeywordHash || termsWithKeywordHash.size === 0) && !IsUpgrade && (
        <button className={`create-content-btn button is-primary`} onClick={() => handleCreateContent()}>
          <p className={"btn-txt"}>
            Create Content for {glossaryWords.length || 0} Terms
          </p>
        </button>
      )}
      {/* {projectTaskMap.has(projectId as string) && termsWithKeywordHash.size !== glossaryWords.length && (
        <button
          className="custom_button"
          onClick={handleRetryTask}
        >
          Retry
        </button>
      )} */}

      <div className={"abun-table-button-container"}>
        <button
          className={`button is-primary is-small is-justify-content-space-between is-flex is-align-items-center`}
          onClick={() => setShowCreateCustomTitleModal(true)}
        >
          <p className={"btn-txt"}> + Add Custom Term</p>
        </button>
      </div>

      {loading ? (
        <p style={{ textAlign: "center", fontSize: "1.3rem" }}>
          Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
        </p>
      ) : glossaryWords.length > 0 ? (
        <div className={`table-container ${bulkActionsEnabled ? 'has-checkbox-header' : ''}`}>
          <AbunTable
            ref={tableRef}
            tableContentName={"Glossary Words"}
            tableData={glossaryWordsWithTerm}
            columnDefs={columnDefs}
            pageSizes={pageSizes}
            initialPageSize={pageSizes[6]}
            noDataText={getNoDataText(selectedTab)}
            searchboxPlaceholderText={"Search Glossary Words..."}
            handleRowClick={(row, event) => {
              const term = row.term;
              if (termsWithKeywordHash.has(term)) {
                navigate(`/glossary-content/${termsWithKeywordHash.get(term)}`);
              }
            }
            }
            enableSorting={true}
            rowCheckbox={true}
            selectedTab={selectedTab}
            GlossaryContentGeneration={true}
            filterTabs={[
              {
                name: "Generated Glossaries",
                onClick: () => setSelectedTab("Generated Glossaries")
              },
              {
                name: "Published Glossaries",
                onClick: () => setSelectedTab("Published Glossaries")
              },
              {
                name: "Archived Glossaries",
                onClick: () => setSelectedTab("Archived Glossaries")
              }
            ]}
            bulkActions={!selectedRows?.size ? [] : availableBulkActions}
            bulkActionsEnabled={bulkActionsEnabled}
            applyAction={(action) => {
              if (action === "Archive") {
                handleBulkAction(action); // Call handleBulkAction if action is "Archive"
              } else {
                setSelectedAction(action);
                setShowApplyModel(true);
              }
            }}
          />
        </div>

      ) : (
        <div>No titles generated yet. Try submitting a glossary word!</div>
      )}

      {
        showApplyModel && (
          <div className={"blur-background "}>
            <div className={"confirmation-card w-100 mt-4"}>
              <button className={"delete is-pulled-right"}
                onClick={() => setShowApplyModel(false)}
              />
              <div className={"confirmation-card-content w-100"}>
                <h3 className={"is-size-5 has-text-centered"}>
                  Choose Integration:
                </h3>
                <div className={"comp-research-location-select"}>
                  <div className={"form-group location-select"}>
                    <Autocomplete
                      id="country-select-demo"
                      sx={{ width: 300 }}
                      options={pageData.all_integrations_with_unique_id
                        .filter(integration =>
                          integration.integrationName.toLowerCase().includes("wordpress"))
                        .map(integration => integration.integrationName) || []}
                      value={selectedIntegration}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Integration"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                          }}
                        />
                      )}
                      onChange={(event, option) => {
                        if (option) {
                          setSelectedIntegration(option);
                        }
                      }}
                    />
                  </div>
                  <AbunButton type={"primary"}
                    className={"mt-5 is-block ml-auto mr-auto"}
                    clickHandler={() => handleBulkAction(selectedAction)}
                  >Publish
                  </AbunButton>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {/* ------------------------------ CREATE CUSTOM TITLE MODAL ------------------------------ */}
      <AbunModal active={showCreateCustomTitleModal}
        headerText={"Write a Custom Glossary Term For : " + pageData.word}
        closeable={true}
        hideModal={() => {
          setShowCreateCustomTitleModal(false)
        }}>
        <div className={"has-text-centered"}>
          <input type="text" className={"input "} placeholder={"Enter your custom term here..."} onChange={(e) => {
            setCustomTitle(e.target.value);
          }} />
          <AbunButton type={"success"}
            className={"mt-4"}
            disabled={updateGlossary.isLoading}
            clickHandler={() => {

              if (!customTitle) return;

              // Check if the term already exists in the glossary_words array
              if (pageData.glossary_words.includes(customTitle)) {
                errorAlertRef.current?.show(`Term "${customTitle}" already exists in the glossary.`);
                setTimeout(() => {
                  errorAlertRef.current?.close();
                }, 5000);
                return;
              }

              // Add the custom title to the glossary_words array
              const updatedGlossaryWords = [customTitle, ...pageData.glossary_words];
              updateGlossary.mutate({
                projectId: projectId as string,
                glossaryWords: updatedGlossaryWords,
                word: pageData.word,
              }, {
                onSuccess: () => {
                  setShowCreateCustomTitleModal(false);
                  successAlertRef.current?.show(`Term "${customTitle}" added successfully!`);
                  setTimeout(() => {
                    successAlertRef.current?.close();
                  }, 5000);
                  setGlossaryWords(updatedGlossaryWords);
                },
                onError: () => {
                  errorAlertRef.current?.show("Failed to add custom title. Please try again after some time.");
                  setTimeout(() => {
                    errorAlertRef.current?.close();
                  }, 5000);
                }
              });
            }}>
            Proceed
          </AbunButton>
        </div>
      </AbunModal>

      {/* ------------------------------ ONGOING GLOSSARY REQUEST MODAL ------------------------------ */}
      <AbunModal active={requestGlossaryModalActive}
        headerText={""}
        modelWidth="450px"
        closeable={false}
        hideModal={() => {
          setRequestGlossaryModalActive(false)
        }}
        backgroundClass="lg-tail-kw-modal-background"
        modalCardClass="lg-tail-kw-abun-modal-card"
      >
        <span className={"back-btn"} onClick={goBackModal}>
          <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
          </svg>
        </span>
        <div className="loader-container" aria-live="polite" aria-busy={loadingContent}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '30px'
          }}>
          {/* ldrs loader */}
          <l-quantum size="60" speed="1.75" color="#3F77F8"></l-quantum>
        </div>
        <h3 className="modal-header-text">Please hold on</h3>
        <p className="modal-subtext">Processing request. Please wait...</p>
      </AbunModal>


      {/* ------------------------------ ONGOING PUBLISH REQUEST MODAL ------------------------------ */}
      <AbunModal active={requestModalActive}
        headerText={""}
        closeable={false}
        hideModal={() => {
          setRequestModalActive(false)
        }}>
        <div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
          <AbunLoader show={requestModalActive} height="20vh" />
        </div>
        <p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
      </AbunModal>

      <SuccessAlert ref={successAlertRef} />
      <ErrorAlert ref={errorAlertRef} />
    </div>
  );
};

export default GlossaryTopic;

import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import SuccessNavbar from "../components/SuccessNavbar/SuccessNavbar";
import GenericButton from '../components/GenericButton/GenericButton';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { pageURL } from "./routes";

import "./errorPage.scss";

interface ReactRouterError {
    message: string
}

export default function AppSumoErrorPage() {
    const navigate = useNavigate();

    const error: ReactRouterError = useRouteError() as ReactRouterError;
    console.log(`################## ${error} #################`);

    useEffect(() => {
        document.title = "Activating AppSumo | Abun";

        // Set up auto-refresh every 5 seconds
        // const intervalId = setInterval(() => {
        //     window.location.reload();
        // }, 5000);

        // Clean up the interval on component unmount
        // return () => clearInterval(intervalId);
    }, []);

    const RedirectCreateArticle = () =>{
        navigate(pageURL['createArticle'])
    }

    return (
        <section className={"error-page"}>
            <SuccessNavbar />
            <div className={"card px-6 has-text-centered"}>
                <div className={"error-icon"}>🚀</div>
                <h1 className={"is-size-3 has-text-weight-bold"}>Processing... Activating your deal</h1>
                <p className={"has-text-centered"}>
                    This should be quick! Please stay on this page.<br />
                    If you are facing issue please contact us via live chat.
                </p>
                <GenericButton
                    text={"Create Article"}
                    type={"secondary"}
                    width={"219px"}
                    height={"40px"}
                    left={"7px"}
                    outlined={true}
                    additionalClassList={["is-small", "more-rounded-borders"]}
                    clickHandler={RedirectCreateArticle}
                    style={{ fontSize: "1rem", backgroundColor: "#3F77F8", color: "white", marginTop: "10px" }}
                />
            </div>
        </section>
    );
}
